<mat-dialog-content>
  <div>
    <mat-form-field class="form-field" color="accent">
      <mat-label>Id</mat-label>
      <input [(ngModel)]="id" matInput>
    </mat-form-field>
    <mat-form-field class="form-field" color="accent">
      <mat-label>Name</mat-label>
      <input [(ngModel)]="name" matInput>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field class="form-field" color="accent">
      <mat-label>Content Type</mat-label>
      <input [(ngModel)]="contenttype" matInput [placeholder]="'text/plain'">
    </mat-form-field>

    <mat-form-field class="form-field" color="accent">
      <mat-label>Version</mat-label>
      <input [(ngModel)]="version" matInput [placeholder]="'1.0'">
    </mat-form-field>
  </div>

  <div>
    <mat-form-field class="form-field" color="accent" id="form-field-destination">
      <mat-label>Destination</mat-label>
      <mat-select [(ngModel)]="storageTypeId">
        <mat-option *ngFor="let storageType of storageTypes " [value]="storageType.id">
          {{storageType.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="form-field" color="accent" id="form-field-account">
      <mat-label *ngIf="storageTypeId==='AmazonS3'">Region</mat-label>
      <mat-label *ngIf="storageTypeId==='MinioS3'">Region</mat-label>
      <mat-label *ngIf="storageTypeId==='AzureStorage'">Account</mat-label>
      <input [(ngModel)]="param1" matInput *ngIf="storageTypeId==='AzureStorage'">
      <mat-select [(ngModel)]="param1" *ngIf="storageTypeId==='AmazonS3'">
        <mat-option *ngFor="let awsRegion of awsRegions" [value]="awsRegion">
          {{awsRegion}}
        </mat-option>
      </mat-select>
      <mat-select [(ngModel)]="param1" *ngIf="storageTypeId==='MinioS3'">
        <mat-option *ngFor="let awsRegion of awsRegions" [value]="awsRegion">
          {{awsRegion}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="form-field" color="accent" id="form-field-container">
      <mat-label *ngIf="storageTypeId==='AmazonS3'">Bucket Name</mat-label>
      <mat-label *ngIf="storageTypeId==='MinioS3'">Bucket Name</mat-label>
      <mat-label *ngIf="storageTypeId==='AzureStorage'">Container Name</mat-label>
      <input [(ngModel)]="param2" matInput>
    </mat-form-field>

    <mat-form-field class="form-field" color="accent" id="form-field-blobname">
      <mat-label *ngIf="storageTypeId==='AmazonS3'">Key Name</mat-label>
      <mat-label *ngIf="storageTypeId==='MinioS3'">Key Name</mat-label>
      <mat-label *ngIf="storageTypeId==='AzureStorage'">Blob Name</mat-label>
      <input [(ngModel)]="param3" matInput>
    </mat-form-field>
  </div>
</mat-dialog-content>


<mat-dialog-actions>
    <button (click)="onSave()" color="accent" mat-stroked-button>
        <mat-icon>add_circle_outline</mat-icon> Create
    </button>

    <button color="accent" mat-dialog-close mat-stroked-button>
        <mat-icon>cancel</mat-icon> Cancel
    </button>
</mat-dialog-actions>
